import React from 'react';
import './MainPage.scss';
import { useNavigate } from 'react-router-dom';
import UserCard from 'containers/UserCard';
import { useAppDispatch, useAppSelector } from 'store';
import { asyncFetchUser } from 'store/slices/thunks';

function MainPage(): React.ReactElement {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | null>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const onLoadComplete = (): void => {
        setLoading(false);
        setTimeout(() => inputRef.current?.focus(), 0);
    };

    const onError = (err?: { detail: string }): void => {
        setError(err?.detail || 'Неизвестная ошибка');
    };

    const onLoad = (): void => {
        setLoading(true);
        setError(null);
    };

    const handleEnter = React.useCallback((): void => {
        const input = inputRef.current;

        if (!input) return;

        onLoad();

        const vkId = input.value;

        if (!vkId) {
            setError('Неверный формат билета');

            return;
        }

        dispatch(asyncFetchUser(vkId))
            .unwrap()
            .catch(onError)
            .finally(onLoadComplete);
        input.value = '';
    }, [dispatch]);

    const navigate = useNavigate();

    React.useEffect(() => {
        if (!localStorage.token) navigate('/login');
    }, [navigate]);

    const { user } = useAppSelector(store => store.userStore);

    return (
        <div className="main-page">
            <input
                ref={inputRef}
                disabled={loading}
                type="text"
                autoFocus
                onKeyDown={(e): void => {
                    if (e.key === 'Enter') handleEnter();
                }}
            />
            {!!error && <div className="error">{error}</div>}
            {loading && <div className="loader" />}
            {user && !loading && <UserCard user={user} />}
        </div>
    );
}

export default React.memo(MainPage);
