import React from 'react';

import { RouteObject } from 'react-router-dom';
import MainPage from 'pages/MainPage';
import LoginPage from 'pages/login/LoginPage';
import ScorePage from 'pages/scores/ScorePage';

const routes: RouteObject[] = [
    {
        path: '',
        element: <MainPage />,
    },
    {
        path: 'scores',
        element: <ScorePage />,
    },
    {
        path: 'login',
        element: <LoginPage />,
    },
];

export default routes;
