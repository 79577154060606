import React from 'react';
import './ScorePage.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { asyncFetchActivities, asyncFetchUserInfo } from 'store/slices/thunks';

function ScorePage(): React.ReactElement {
    const dispatch = useAppDispatch();
    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleEnter = (): void => {
        if (!inputRef.current) return;

        dispatch(asyncFetchActivities(inputRef.current.valueAsNumber));
        dispatch(asyncFetchUserInfo(inputRef.current.valueAsNumber));
    };

    const { scores, userInfo } = useAppSelector(store => store.userStore);

    const scoresSum = React.useMemo(() => {
        if (!scores?.length) return 0;

        return scores.reduce((sum, val) => sum + val.amount, 0);
    }, [scores]);

    return (
        <div className="score-page">
            <input type="number" ref={inputRef} autoFocus />
            <button type="button" onClick={handleEnter}>
                Запросить
            </button>

            {userInfo && (
                <h1 style={{ textAlign: 'center' }}>
                    У {userInfo.user.first_name} {userInfo.user.first_name}{' '}
                    <br />
                    {scoresSum} КенКоинов
                </h1>
            )}
        </div>
    );
}

export default React.memo(ScorePage);
