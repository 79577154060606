import dayjs from 'dayjs';
import React from 'react';
import { IUser } from 'store/slices/types';

function ScannedInfo({
    dateActivated,
}: {
    dateActivated: string;
}): React.ReactElement {
    const lastScannedTime = dayjs(dateActivated).add(3, 'hour');

    const secondsSinceLastScan = dayjs().diff(lastScannedTime, 'second');
    const minutesSinceLastScan = Math.floor(secondsSinceLastScan / 60);
    const remainingSeconds = secondsSinceLastScan % 60;

    const formatElapsedTime = (minutes: number, seconds: number): string => {
        let result = '';

        if (minutes > 0) {
            result += `${minutes} минут${minutes === 1 ? 'а' : 'ы'} и `;
        }

        result += `${seconds} секунд${seconds === 1 ? 'а' : ''}`;

        return result;
    };

    return (
        <span className="time red">
            {lastScannedTime.format('HH:mm:ss')} (прошло{' '}
            {formatElapsedTime(minutesSinceLastScan, remainingSeconds)})
        </span>
    );
}

export default function UserCard({
    user,
}: {
    user: IUser;
}): React.ReactElement {
    const lastScannedTime = dayjs(user.date_activated).add(3, 'hour');
    const wasScanned = dayjs().diff(lastScannedTime, 'second') > 1;
    return (
        <div className="user">
            <img src={user.user.avatar_url} alt="avatar url" />
            <div className="info">
                <div className="id">
                    ID: <span className="user-id">{user.id}</span>
                </div>
                <div className="name">
                    <span>{user.user.first_name}</span>
                </div>
                <div className="surname">
                    <span>{user.user.last_name}</span>
                </div>
                <a
                    href={`https://vk.com/id${user.user.vk_id}`}
                    target="_blank"
                    className="vk"
                    rel="noreferrer"
                >
                    https://vk.com/id{user.user.vk_id}
                </a>
                <div className="scanned">
                    Сканирован:{' '}
                    {wasScanned && (
                        <span className="time red">
                            <ScannedInfo dateActivated={user.date_activated} />
                        </span>
                    )}
                    {!wasScanned && <span className="time">никогда</span>}
                </div>
            </div>
        </div>
    );
}
