import axios, { AxiosInstance, RawAxiosRequestHeaders } from 'axios';

const { REACT_APP_BACKEND_URL } = process.env;

export type TokenType = 'token' | undefined;

export function setToken(type: TokenType, token: string): void {
    if (type) localStorage[type] = token;
}

export function removeToken(type: TokenType): void {
    if (type) localStorage.removeItem(type);
}

export function getToken(type: TokenType): string | null {
    return type ? localStorage.getItem(type) : null;
}

export default function getApiClient(
    type: TokenType = undefined
): AxiosInstance {
    const headers: RawAxiosRequestHeaders = {};

    const token = getToken(type);

    if (token) headers.Authorization = `Bearer ${token}`;

    const instance = axios.create({
        baseURL: REACT_APP_BACKEND_URL,
        responseType: 'json',
        headers,
    });

    instance.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status === 401) {
                removeToken(type);
            }

            return Promise.reject(error);
        }
    );

    return instance;
}
