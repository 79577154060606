import React from 'react';
import './LoginPage.scss';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { asyncLoginUser } from 'store/slices/thunks';

function LoginPage(): React.ReactElement {
    const login = React.useRef<HTMLInputElement>(null);
    const password = React.useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleLogin = (): void => {
        dispatch(
            asyncLoginUser({
                username: login.current?.value || '',
                password: password.current?.value || '',
            })
        )
            .unwrap()
            .then(() => {
                navigate('/');
            });
    };

    return (
        <div className="login-page">
            <div className="login-form">
                <input placeholder="Логин" ref={login} type="text" />
                <input placeholder="Пароль" ref={password} type="password" />
                <button type="submit" onClick={handleLogin}>
                    Войти
                </button>
            </div>
        </div>
    );
}

export default React.memo(LoginPage);
