import { createSlice } from '@reduxjs/toolkit';
import { setMetalRate, setScores, setToken, setUser } from './reducer';
import {
    asyncFetchActivities,
    asyncFetchUser,
    asyncFetchUserInfo,
    asyncLoginUser,
} from './thunks';
import { IMetalRateState } from './types';

const initialState: IMetalRateState = {
    user: undefined,
    scores: undefined,
    userInfo: undefined,
};

export const reducers = {};

const slice = createSlice({
    name: 'metalRates',
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(asyncFetchUser.fulfilled, setMetalRate);
        builder.addCase(asyncFetchActivities.fulfilled, setScores);
        builder.addCase(asyncFetchUserInfo.fulfilled, setUser);
        builder.addCase(asyncLoginUser.fulfilled, setToken);
    },
});

export default slice.reducer;
