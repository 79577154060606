import { IPayloadAction } from 'store/type';
import { IMetalRateState, IScore, IUser } from './types';

export function setMetalRate(
    state: IMetalRateState,
    action: IPayloadAction<IUser>
): IMetalRateState {
    return {
        ...state,
        user: action.payload,
    };
}

export function setScores(
    state: IMetalRateState,
    action: IPayloadAction<IScore[]>
): IMetalRateState {
    return {
        ...state,
        scores: action.payload,
    };
}
export function setUser(
    state: IMetalRateState,
    action: IPayloadAction<IUser>
): IMetalRateState {
    return {
        ...state,
        userInfo: action.payload,
    };
}
export function setToken(
    _: IMetalRateState,
    action: IPayloadAction<string>
): void {
    localStorage.token = action.payload;
}
