import { createAsyncThunk } from '@reduxjs/toolkit';
import getApiClient from 'js/utils/apiClient';
import { IScore, IUser, LoginForm } from './types';

const asyncFetchUser = createAsyncThunk<IUser, string>(
    'user/fetchUser',
    async (barcode, { rejectWithValue }) =>
        getApiClient('token')
            .get(`/tickets/activations/${barcode}/`)
            .then(response => response.data)
            .catch(error => rejectWithValue(error.response?.data))
);

const asyncFetchActivities = createAsyncThunk<IScore[], number>(
    'user/fetchActivities',
    async (user_id, { rejectWithValue }) =>
        getApiClient('token')
            .get(`/users/${user_id}/score/`)
            .then(response => response.data)
            .catch(error => rejectWithValue(error.message))
);

const asyncFetchUserInfo = createAsyncThunk<IUser, number>(
    'user/fetchUserInfo',
    async (user_id, { rejectWithValue }) =>
        getApiClient('token')
            .get(`/users/${user_id}/info/`)
            .then(response => response.data)
            .catch(error => rejectWithValue(error.message))
);

const asyncLoginUser = createAsyncThunk<string, LoginForm>(
    'user/login',
    async (loginForm, { rejectWithValue }) =>
        getApiClient()
            .post(`/users/auth/`, { ...loginForm })
            .then(response => response.data.token)
            .catch(error => rejectWithValue(error.message))
);

export {
    asyncFetchUser,
    asyncLoginUser,
    asyncFetchActivities,
    asyncFetchUserInfo,
};
