import React, { ReactElement } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'styles/styles.sass';
import routes from 'routes/routes';

function App(): ReactElement {
    const router = createBrowserRouter(routes);

    return (
        <div className="app">
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
